import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
// APP
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
const KanbanPageSample = lazy(() => import('src/pages/dashboard/kanban_sample'));
const WhyNumylo  = lazy(() => import('src/pages/dashboard/whyNumylo'))

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'salesloft', element: <OverviewEcommercePage /> },
      { path: 'gong', element: <OverviewAnalyticsPage /> },
      { path: 'new', element: <KanbanPage /> },
      { path: 'sample', element: <KanbanPageSample /> },
      { path: 'why-numylo', element: <WhyNumylo /> },
    ],
  },
];
