import { paths } from 'src/routes/paths';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import Iconify from 'src/components/iconify';


export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'Presentation',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.presentation,
  },
  {
    title: 'Demo',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: PATH_AFTER_LOGIN,
  }
];
