import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { Box } from '@mui/system';
//
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const [weatherData, setWeatherData] = useState(null)

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  // eslint-disable-next-line arrow-body-style
  const getLocation = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error.message);
          reject(error);
        }
      );
    });
  };

  const getWeatherData = async (location) => {
    const apikey = process.env.REACT_APP_WEATHER_API_KEY 
    const url = `https://api.weatherapi.com/v1/current.json?key=${apikey}&q=${location}&aqi=no`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data) {
        return data;
        // eslint-disable-next-line no-else-return
      } else {
        console.error('Error fetching weather data:', data.error);
      }
    } catch (error) {
      console.error('Error fetching weather data:', error.message);
    }

    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const location = await getLocation();
        // eslint-disable-next-line prefer-template
        const weatherDetails = await getWeatherData(location.latitude + ',' + location.longitude);
        setWeatherData(weatherDetails);
      } catch (error) {
        console.error('Error fetching weather data:', error.message);
      }
    };

    fetchData();
  }, []);

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >

        {/* "Weather Start" */}
        {weatherData ? (
          <Box sx={{display:'flex', alignItems:'center', gap:'10px'}}>
            <img src={weatherData?.current.condition.icon} alt='weather' width='35px' height='35px'/>
            <Typography variant="body2" fontWeight='semibold'>
              {/* {weatherData?.current.temp_c}°C in {weatherData?.location.name}, {weatherData?.location.region}, {weatherData?.location.country} */}
              {weatherData?.current.temp_c}°C in {weatherData?.location.name}
            </Typography>
          </Box>
        ) : <p style={{fontSize:'10px'}}>Loading weather...</p>}
        {/* "Weather End" */}

        <LanguagePopover />

        <NotificationsPopover />

        <ContactsPopover />

        <SettingsButton />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
