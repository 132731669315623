
// import { _id, _postTitles } from 'src/_mock/assets';

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  presentation: '/presentation',
  docs: 'https://docs.numyloSolutions.com',
  changelog: 'https://docs.numyloSolutions.com/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    }
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    why: `${ROOTS.DASHBOARD}/why-numylo`,
    new_dashboard: `${ROOTS.DASHBOARD}/new`,
    sample_dashboard: `${ROOTS.DASHBOARD}/sample`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      salesloft: `${ROOTS.DASHBOARD}/salesloft`,
      gong: `${ROOTS.DASHBOARD}/gong`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
    },
  },
};
